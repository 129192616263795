import { createRoute } from "@tanstack/react-router";
import { useBalance } from "../../api/useBalance";
import { GrossRevenue } from "../../components/dash/gross-revenue";
import { RecentPayments } from "../../components/dash/recent-payments";
import { Information } from "../../components/ui/information";
import { dashLayout } from "../../layouts/dash";
import { numberFormatter } from "../../lib/number";

export const dashIndexRoute = createRoute({
	getParentRoute: () => dashLayout,
	path: "/",
	component: IndexPage,
});

function IndexPage() {
	const { balance, isLoading } = useBalance();

	return (
		<div className="md:py-6 px-6 md:px-12 w-full flex flex-col space-y-4">
			<div className="flex justify-between items-center">
				<h1 className="font-semibold text-2xl">Dashboard</h1>
			</div>
			<div className="grid grid-cols-12 gap-6">
				<GrossRevenue />
				<div className="col-span-12 2xl:col-span-4 p-6 hidden md:flex flex-col">
					<div className="flex items-center space-x-2">
						<span className="text-neutral-600">USD Balance</span>
						<Information
							suggestion="All USD is backed by USDC"
							position="right"
							className="w-[96px] justify-center"
						/>
					</div>
					{!isLoading && !!balance ? (
						<span className="text-xl font-semibold text-neutral-900">
							{numberFormatter(balance.readableAmount, {
								style: "currency",
								currency: "USD",
							})}
						</span>
					) : (
						<div className="w-24 h-6 bg-neutral-200 animate-pulse rounded-lg" />
					)}
				</div>
				<RecentPayments />
			</div>
		</div>
	);
}
