import { getAccessToken } from "@privy-io/react-auth";
import { useMutation } from "@tanstack/react-query";
import { createRoute } from "@tanstack/react-router";
import { z } from "zod";
import { ReloadIcon } from "../../assets/reload";
import { Button } from "../../components/ui/button";
import { dashLayout } from "../../layouts/dash";
import { fetcher } from "../../lib/fetcher";

export const dashDevsRoute = createRoute({
	getParentRoute: () => dashLayout,
	path: "/developers",
	component: Page,
});

function Page() {
	const { mutate, data, isPending } = useMutation({
		mutationKey: ["api-key"],
		mutationFn: async () => {
			return fetcher(
				`${import.meta.env.VITE_API_URL}/dashboard/api-key`,
				z.object({
					apiKey: z.string(),
				}),
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				},
			);
		},
	});

	return (
		<div className="md:py-6 px-6 md:px-12 w-full flex flex-col space-y-4">
			<h1 className="font-semibold text-2xl">Developers</h1>
			<section className="flex flex-col border border-neutral-300 divide-y divide-neutral-300 rounded-lg bg-white">
				<div className="p-4 flex justify-between items-center">
					<div>
						<p className="font-medium">API Keys</p>
						<span className="text-sm text-neutral-700">
							Create an API key to access the Inflow API. This key will allow
							you to access your data programmatically. Please note that this
							will invalidate your current key immediately.
						</span>
					</div>
					<Button
						onClick={() => mutate()}
						icon={<ReloadIcon className="w-4 h-4 fill-neutral-700" />}
						iconPosition="left"
					>
						<span className="whitespace-nowrap">Rotate key</span>
					</Button>
				</div>
				{(data?.apiKey || isPending) && (
					<div className="p-4 flex justify-between items-center">
						<span className="font-medium">New API Key:</span>
						{data?.apiKey ? (
							<code className="text-sm bg-neutral-100 text-neutral-800 px-2 py-1 rounded-md">
								{data.apiKey}
							</code>
						) : isPending ? (
							<span className="text-sm text-neutral-700">Generating...</span>
						) : null}
					</div>
				)}
			</section>
		</div>
	);
}
