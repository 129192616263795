import { z } from "zod";

export const payoutStatusSchema = z.enum([
	"INITIATED",
	"SENT",
	"PROCESSING",
	"SUBMITTED",
	"COMPLETED",
	"CANCELED",
	"FAILED",
	"RETURNED",
	"REFUNDED",
]);

export type PayoutStatus = z.infer<typeof payoutStatusSchema>;

export const payoutTimelineSchema = z
	.object({
		status: payoutStatusSchema,
		timestamp: z.coerce.date(),
	})
	.array();

export const payoutSchema = z.object({
	id: z.string(),
	amountInCents: z.number(),
	currency: z.enum(["USDC"]),
	status: payoutStatusSchema,
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date(),
	payment: z.object({
		sourceAmount: z.coerce.number(),
		sourceCurrency: z.enum(["usdc"]),
		sourceSubtotalAmount: z.coerce.number(),
		finalAmount: z.coerce.number(),
		finalCurrency: z.enum(["eur", "usdc"]),
		exchangeRate: z.coerce.number(),
		rail: z.enum(["sepa", "polygon"]),
		txHash: z.string().nullable().optional(),
	}),
});

export type Payout = z.infer<typeof payoutSchema>;
