import { useCreateWallet, usePrivy, useWallets } from "@privy-io/react-auth";
import { useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect } from "react";
import { useSignup } from "../api/mutations/use-signup";

export function useSyncAutoLogin(next: string | undefined = "/") {
	const { authenticated, user } = usePrivy();
	const { wallets } = useWallets();
	const { createWallet } = useCreateWallet();
	const navigate = useNavigate();

	const { mutate } = useSignup({
		onSuccess: () => {
			navigate({ to: "/merchant", search: { next } });
		},
	});

	const createWalletAndSignup = useCallback(
		async (email: string) => {
			if (authenticated) {
				const wallet = await createWallet();

				mutate({
					email,
					wallet: wallet.address,
				});
			}
		},
		[authenticated, mutate, createWallet],
	);

	useEffect(() => {
		const embeddedWallet = wallets.find(
			(wallet) => wallet.walletClientType === "privy",
		);

		if (authenticated && !embeddedWallet && user?.email) {
			createWalletAndSignup(user.email.address);
		}
	}, [authenticated, user?.email, wallets, createWalletAndSignup]);
}
