import { zodResolver } from "@hookform/resolvers/zod";
import { useLoginWithEmail, usePrivy } from "@privy-io/react-auth";
import {
	createRoute,
	redirect,
	useNavigate,
	useSearch,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { InflowIcon } from "../../assets/inflow";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { useSyncAutoLogin } from "../../hooks/use-sync-auto-login";
import { getUser } from "../../lib/get-user";
import { authLayout } from "../_auth";

const searchSchema = z.object({
	next: z.string().optional(),
});

export const loginRoute = createRoute({
	getParentRoute: () => authLayout,
	path: "/login",
	component: Page,
	loaderDeps: ({ search: { next } }) => ({ next }),
	loader: async ({ deps, context }) => {
		const user = await getUser(context.accessToken);

		if (!user) {
			return;
		}

		if (user?.legalName === null) {
			throw redirect({
				to: "/merchant",
				search: {
					next: deps.next || "/",
				},
			});
		}

		// waiting to get history -1, so this is a custom workaround to get history
		// https://github.com/TanStack/router/discussions/305#discussioncomment-3351924
		if (deps.next === "/links/new") {
			throw redirect({
				to: deps.next,
				search: {
					products: [],
					isEmailRequired: true,
					back: "/",
				},
			});
		}

		throw redirect({
			to: deps.next || "/",
		});
	},
	validateSearch: searchSchema,
});

const formValuesSchema = z.object({
	email: z.string().email(),
});

type FormValues = z.infer<typeof formValuesSchema>;

function Page() {
	const { next } = useSearch({ from: "/_auth/login" });
	const navigate = useNavigate();
	const {
		handleSubmit,
		register,
		formState: { errors },
		setError,
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
	});
	const { ready } = usePrivy();
	const { sendCode, state } = useLoginWithEmail({
		onError: (error) => {
			if (error === "allowlist_rejected") {
				setError("email", {
					message: "You are not authorized",
				});
			} else {
				setError("email", {
					message: "An error occured",
				});
			}
		},
	});

	useSyncAutoLogin(next);

	async function onSubmit({ email }: FormValues) {
		await sendCode({ email });

		navigate({
			to: "/otp",
			search: {
				next,
				email,
			},
		});
	}

	return (
		<div className="w-full px-4 md:max-w-md flex flex-col items-start space-y-12">
			<InflowIcon className="w-20 h-8 fill-black" />
			<form
				className="w-full flex flex-col space-y-4"
				onSubmit={handleSubmit(onSubmit)}
			>
				<h1 className="text-xl font-medium">Log in to your account</h1>
				<div className="flex flex-col space-y-1">
					<label
						htmlFor="email"
						className="text-sm text-neutral-700 font-medium"
					>
						Email address
					</label>
					<Input id="email" type="text" autoFocus {...register("email")} />
					{errors.email && (
						<span className="text-sm text-red-500">{errors.email.message}</span>
					)}
				</div>
				<Button
					type="submit"
					color="inflow"
					intent="solid"
					disabled={!ready}
					className="w-full !py-2"
				>
					{state.status === "sending-code" ? "Sending code..." : "Send code"}
				</Button>
			</form>
			<span className="text-xs text-neutral-200">
				App version: {import.meta.env.MODE}.{import.meta.env.COMMIT_HASH}
			</span>
		</div>
	);
}
